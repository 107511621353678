import React from 'react';
import styled from 'styled-components';
import WebLinkIcon from 'assets/svg/weblink.svg';
import WebLinkWhiteIcon from 'assets/svg/weblink-white.svg';
import { TxtDefaultMediumLightResp, TxtDefaultMediumAccentResp } from 'elements/new-design/Typography';

type ModalLinkProps = {
  text: string;
  underline?: boolean;
  onModalLinkClick: () => void;
};

type StyleProps = {
  underline?: boolean;
  white?: boolean;
};

export const ButtonLink = styled.button`
  cursor: pointer;

  &:focus-visible {
    box-shadow: ${(props) => props.theme.constants.borderFocusLight};
  }
`;

export const ButtonText = styled.p`
  ${(_: StyleProps) => ''}
  color: ${(props) => props.theme.colors.txtColAccent};
  font-family: ${(props) => props.theme.fonts.fontFamily.bold};
  font-size: ${(props) => props.theme.fonts.fontSize.default};
  text-decoration: ${(props) => (props.underline ? 'underline' : '')};
  &:focus-visible {
    box-shadow: ${(props) => props.theme.constants.borderFocusLight};
  }
`;

export const TxtLinkSmall = styled.a`
  color: ${(props) => props.theme.colors.txtColAccent};
  font-size: ${(props) => props.theme.fonts.fontSize.small};
  font-family: ${(props) => props.theme.fonts.fontFamily.bold};
  cursor: pointer;
  align-items: center;

  &:focus-visible {
    box-shadow: ${(props) => props.theme.constants.borderFocusLight};
  }
`;

export const TxtLinkUnderline = styled(TxtLinkSmall)`
  text-decoration: underline;
`;

export const TxtLinkDefault = styled(TxtLinkUnderline)`
  color: ${(props) => props.theme.colors.txtColDark};
  font-size: ${(props) => props.theme.fonts.fontSize.default};
  font-family: ${(props) => props.theme.fonts.fontFamily.medium};
`;

export const ModalLink = ({ text, onModalLinkClick, underline }: ModalLinkProps) => (
  <ButtonLink type="button" data-cy={'btnLinkCy'} onClick={onModalLinkClick}>
    <ButtonText underline={underline}>{text}</ButtonText>
  </ButtonLink>
);

type LinkProps = {
  text: string;
  url: string;
  white?: boolean;
  bold?: boolean;
  onLinkClick?: () => void;
};

export const ExternalLinkIcon = styled.svg`
  ${(_: StyleProps) => ''}
  width: 2rem;
  height: 2rem;
  margin-left: 0.5rem;
  background: ${(props) => (props.white ? `url(${WebLinkWhiteIcon})` : `url(${WebLinkIcon})`)} no-repeat center;
  background-size: 1.8rem;
`;

export const ExternalLink = ({ text, url, white, ...props }: LinkProps) => (
  <TxtLinkSmall
    href={url}
    target="_blank"
    rel="noreferrer"
    tabIndex={0}
    onClick={props.onLinkClick}
    onKeyDown={(e) => e.stopPropagation()}
  >
    {white ? (
      <TxtDefaultMediumLightResp>{text}</TxtDefaultMediumLightResp>
    ) : (
      <TxtDefaultMediumAccentResp>{text}</TxtDefaultMediumAccentResp>
    )}

    <ExternalLinkIcon white={white} />
  </TxtLinkSmall>
);
