import { useSelector } from 'react-redux';
import { AppState } from 'store/index';
import { useDevice } from 'hooks/useDevice';
import { useAuthentication } from 'hooks/useAuthentication';
import { useHistoryApi } from './useHistoryApi';
import { useCustomerApi } from './useCustomerApi';
import { useJourneyApi } from './useJourneyApi';
import { useTicketApi } from './useTicketApi';
import { useRecurringPaymentApi } from './useRecurringPaymentApi';
import { useLocationApi } from './useLocationApi';
import { useSeatingApi } from './useSeatingApi';
import { useCmsApi } from './use-cms-api/useCmsApi';
import { useAssistanceApi } from './useAssistanceApi';
import { useCampaignApi } from './useCampaignApi';
import { useDeviationApi } from './useDeviationApi';
import { useExternalCampaignsApi } from './useExternalCampaignsApi';
import { useEntitlementApi } from './useEntitlementApi';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import { Config } from 'utils/Config';
import axios from 'axios';

const API_CALLS = {
  CMS_GET_PAGES_WITH_SLUGS: 'CMS_GET_PAGES_WITH_SLUGS',
  CMS_GET_IMAGE_URL: 'CMS_GET_IMAGE_URL',
  CMS_GET_CMS_LINKS: 'CMS_GET_CMS_LINKS',
  CMS_GET_LANDING_PAGE: 'CMS_GET_LANDING_PAGE',
  CMS_GET_COOKIES: 'CMS_FETCH_COOKIES',
  CMS_GET_MAINPAGE: 'CMS_GET_MAINPAGE',
  CMS_GET_MAINPAGE_PREVIEW: 'CMS_GET_MAINPAGE_PREVIEW',
  CMS_GET_PAGE_TITLE: 'CMS_GET_PAGE_TITLE',
  CMS_GET_PAGE: 'CMS_GET_PAGE',
  CMS_GET_PAGE_PREVIEW: 'CMS_GET_PAGE_PREVIEW',
  CMS_GET_PARTNER_PAGE: 'CMS_GET_PARTNER_PAGE',
  CMS_GET_SYSTEM_MESSAGES: 'CMS_GET_SYSTEM_MESSAGES',
  GET_ASSISTANCE_OPTIONS: 'GET_ASSISTANCE_OPTIONS',
  SEND_ASSISTANCE: 'SEND_ASSISTANCE',
  CAMPAIGN_RESERVE: 'CAMPAIGN_RESERVE',
  GET_PROFILE: 'GET_PROFILE',
  CREATE_PROFILE: 'CREATE_PROFILE',
  CREATE_TEMP_PROFILE: 'CREATE_TEMP_PROFILE',
  UPDATE_TEMP_PROFILE: 'UPDATE_TEMP_PROFILE',
  EDIT_PROFILE: 'EDIT_PROFILE',
  DELETE_PROFILE: 'DELETE_PROFILE',
  GET_CONSENTS: 'GET_CONSENTS',
  EDIT_CONSENTS: 'EDIT_CONSENTS',
  VERIFY_CUSTOMER_CREDENTIALS_OLD: 'VERIFY_CUSTOMER_CREDENTIALS_OLD',
  GET_AVAILABLE_CONSENTS: 'GET_AVAILABLE_CONSENTS',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  UPDATE_PASSWORD: 'UPDATE_PASSWORD',
  GET_ALL_DEVIATIONS: 'GET_ALL_DEVIATIONS',
  GET_CRITICAL_DEVIATIONS: 'GET_CRITICAL_DEVIATIONS',
  GET_ORDER_BY_ID: 'GET_ORDER_BY_ID',
  GET_TRANSACTION_BY_ID: 'GET_TRANSACTION_BY_ID',
  GET_TRANSACTIONS: 'GET_TRANSACTIONS',
  GET_JOURNEYS: 'GET_JOURNEYS',
  GET_OFFERS: 'GET_OFFERS',
  GET_JOURNEY_PLAN: 'GET_JOURNEY_PLAN',
  GET_LATER_LEG_DEPARTURES: 'GET_LATER_LEG_DEPARTURES',
  GET_EARLIER_LEG_DEPARTURES: 'GET_EARLIER_LEG_DEPARTURES',
  REPLACE_LEG_IN_JOURNEY: 'REPLACE_LEG_IN_JOURNEY',
  GET_LOCATIONS: 'GET_LOCATIONS',
  GET_RECURRING_PAYMENTS: 'GET_RECURRING_PAYMENTS',
  AUTH_RECURRING_PAYMENT: 'AUTH_RECURRING_PAYMENT',
  CREATE_RECURRING_PAYMENTS: 'CREATE_RECURRING_PAYMENTS',
  DELETE_RECURRING_PAYMENTS: 'DELETE_RECURRING_PAYMENTS',
  UPDATE_RECURRING_PAYMENTS: 'UPDATE_RECURRING_PAYMENTS',
  GET_AVAILABLE_SEATS: 'GET_AVAILABLE_SEATS',
  RESERVE_SEATS: 'RESERVE_SEATS',
  SEND_PDF: 'SEND_PDF',
  CAPTURE_NETS: 'CAPTURE_NETS',
  SEND_SMS: 'SEND_SMS',
  GET_VIPPS_TRANSACTION_STATUS: 'GET_VIPPS_TRANSACTION_STATUS',
  CANCEL_VIPPS_TRANSACTION: 'CANCEL_VIPPS_TRANSACTION',
  RESERVE_TICKET: 'RESERVE_TICKET',
  REVIEW_ORDER: 'REVIEW_ORDER',
  ADD_CONSENT: 'ADD_CONSENT',
  REFUND_TICKET: 'REFUND_TICKET',
  SEND_RECEIPT: 'SEND_RECEIPT',
  TICKET_PAY: 'TICKET_PAY',
  ADD_ENTITLEMENT: 'ADD_ENTITLEMENT',
  GET_ENTITLEMENTS: 'GET_ENTITLEMENTS',
  GET_STRAWBERRY_BONUSES: 'GET_STRAWBERRY_BONUSES',
  GET_STRAWBERRY_PROFILE: 'GET_STRAWBERRY_PROFILE',
  UPDATE_STRAWBERRY_PROFILE: 'UPDATE_STRAWBERRY_PROFILE',

  // new goOps below

  REFRESH_TOKEN: 'REFRESH_TOKEN',
  VERIFY_CUSTOMER_CREDENTIALS: 'VERIFY_CUSTOMER_CREDENTIALS',
  GET_TRAINS: 'GET_TRAINS',
  GET_RTV_STATIONS: 'GET_RTV_STATIONS',
  GET_ALL_GOOPS_DEVIATIONS: 'GET_DEVIATIONS',
  GET_CRITICAL_GOOPS_DEVIATIONS: 'GET_CRITICAL_GOOPS_DEVIATIONS',
};

export const useApi = () => {
  const user = useSelector((state: AppState) => state.user);
  const order = useSelector((state: AppState) => state.order);
  const { getUser, getToken, updateToken } = useAuthentication();
  const { device } = useDevice();

  const requestController = new AbortController();
  const apiConfig = {
    baseUrlGoOps: Config().goOpsApi,
    signal: requestController.signal,
  };

  const CustomerApi = () => useCustomerApi(getToken(), getUser(), order);
  const HistoryApi = () => useHistoryApi(getToken());
  const JourneyApi = () => useJourneyApi(getToken(), order);
  const TicketApi = () => useTicketApi(getToken(), getUser(), order);
  const RecurringPaymentApi = () => useRecurringPaymentApi(getToken());
  const LocationApi = () => useLocationApi();
  const SeatingApi = () => useSeatingApi(getToken(), device, order);
  const CmsApi = () => useCmsApi();
  const AssistanceApi = () => useAssistanceApi(getToken());
  const CampaignApi = () => useCampaignApi(getToken());
  const DeviationApi = () => useDeviationApi();
  const ExternalCampaignsApi = () => useExternalCampaignsApi(getToken());
  const EntitlementApi = () => useEntitlementApi(getToken());

  const requestImageUrl = (source: { image: SanityImageSource; width: number }) => CmsApi().fetchImageUrl(source);

  const requestCms = async (request: string, data = null) => {
    switch (request) {
      case API_CALLS.CMS_GET_PAGES_WITH_SLUGS:
        return await CmsApi().fetchPagesWithSlug();
      case API_CALLS.CMS_GET_CMS_LINKS:
        return await CmsApi().fetchLandingPages();
      case API_CALLS.CMS_GET_LANDING_PAGE:
        return await CmsApi().fetchLandingPage(data);
      case API_CALLS.CMS_GET_COOKIES:
        return await CmsApi().fetchCookies();
      case API_CALLS.CMS_GET_SYSTEM_MESSAGES:
        return await CmsApi().fetchSystemMessages(data);
      case API_CALLS.CMS_GET_MAINPAGE:
        return await CmsApi().fetchMainPage();
      case API_CALLS.CMS_GET_MAINPAGE_PREVIEW:
        return await CmsApi().fetchMainPagePreview(data);
      case API_CALLS.CMS_GET_PAGE:
        return await CmsApi().fetchPage(data);
      case API_CALLS.CMS_GET_PAGE_PREVIEW:
        return await CmsApi().fetchPagePreview(data);
      case API_CALLS.CMS_GET_PARTNER_PAGE:
        return await CmsApi().fetchPartnerPage(data);
      default:
        break;
    }
  };

  const request = async (request: string, data = null) => {
    switch (request) {
      case API_CALLS.CREATE_PROFILE:
        return CustomerApi().createProfile(data);
      case API_CALLS.CREATE_TEMP_PROFILE:
        return CustomerApi().createTemporaryCustomerProfile();
      case API_CALLS.GET_AVAILABLE_CONSENTS:
        return CustomerApi().getAvailableConsents();
      case API_CALLS.FORGOT_PASSWORD:
        return CustomerApi().forgotPassword(data);
      case API_CALLS.GET_ALL_DEVIATIONS:
        return DeviationApi().getAllDeviations();
      case API_CALLS.GET_CRITICAL_DEVIATIONS:
        return DeviationApi().getCriticalDeviations();
      case API_CALLS.GET_JOURNEYS:
        return JourneyApi().journeyGet(data);
      case API_CALLS.GET_OFFERS:
        return JourneyApi().getOffers(data);
      case API_CALLS.GET_JOURNEY_PLAN:
        return JourneyApi().getJourneyPlan(data);
      case API_CALLS.GET_LATER_LEG_DEPARTURES:
        return JourneyApi().getLaterDeparturesForLeg(data);
      case API_CALLS.GET_EARLIER_LEG_DEPARTURES:
        return JourneyApi().getEarlierDeparturesForLeg(data);
      case API_CALLS.REPLACE_LEG_IN_JOURNEY:
        return JourneyApi().replaceLegInJourney(data);
      case API_CALLS.GET_LOCATIONS:
        return LocationApi().getGoAheadLocations();
      default:
        break;
    }
  };

  const requestGoOps = async (request: string, data = null) => {
    switch (request) {
      case API_CALLS.VERIFY_CUSTOMER_CREDENTIALS:
        return await axios.post(`${apiConfig.baseUrlGoOps}/customer/login`, data);
      case API_CALLS.REFRESH_TOKEN:
        return await axios.post(`${apiConfig.baseUrlGoOps}/customer/refresh-token`, {
          tokenGoOps: user.tokenGoOps,
          refreshTokenGoOps: user.refreshTokenGoOps,
        });
      case API_CALLS.GET_TRAINS:
        return await axios.get(`${apiConfig.baseUrlGoOps}/trains`);
      case API_CALLS.GET_RTV_STATIONS:
        return await axios.get(`${apiConfig.baseUrlGoOps}/rtv-locations`);
      case API_CALLS.GET_ALL_GOOPS_DEVIATIONS:
        return await axios.get(`${apiConfig.baseUrlGoOps}/deviation`);
      case API_CALLS.GET_CRITICAL_GOOPS_DEVIATIONS:
        return await axios.get(`${apiConfig.baseUrlGoOps}/deviation/critical`);
      default:
        break;
    }
  };

  const makeCall = async (request: string, data = null) => {
    switch (request) {
      case API_CALLS.SEND_ASSISTANCE:
        return await AssistanceApi().sendAssistance(data);
      case API_CALLS.GET_ASSISTANCE_OPTIONS:
        return await AssistanceApi().getAssistanceOptions();
      case API_CALLS.CAMPAIGN_RESERVE:
        return await CampaignApi().reserveCampaign(data);
      case API_CALLS.GET_PROFILE:
        return await CustomerApi().getProfile(data);
      case API_CALLS.EDIT_PROFILE:
        return await CustomerApi().editProfile(data);
      case API_CALLS.DELETE_PROFILE:
        return await CustomerApi().deleteCustomer();
      case API_CALLS.UPDATE_TEMP_PROFILE:
        return await CustomerApi().updateTempProfile();
      case API_CALLS.GET_CONSENTS:
        return await CustomerApi().getConsents();
      case API_CALLS.EDIT_CONSENTS:
        return await CustomerApi().editConsents(data);
      case API_CALLS.VERIFY_CUSTOMER_CREDENTIALS_OLD:
        return await CustomerApi().verifyCustomerCredentials(data);
      case API_CALLS.UPDATE_PASSWORD:
        return await CustomerApi().updatePassword(data);
      case API_CALLS.GET_ORDER_BY_ID:
        return await HistoryApi().getHistoryDetails(data);
      case API_CALLS.GET_TRANSACTION_BY_ID:
        return await HistoryApi().getTransactionById(data);
      case API_CALLS.GET_TRANSACTIONS:
        return await HistoryApi().getTransactionHistory();
      case API_CALLS.GET_RECURRING_PAYMENTS:
        return await RecurringPaymentApi().getRecurringPayments();
      case API_CALLS.CREATE_RECURRING_PAYMENTS:
        return await RecurringPaymentApi().createRecurringPayment(data);
      case API_CALLS.DELETE_RECURRING_PAYMENTS:
        return await RecurringPaymentApi().deleteRecurringPayment(data);
      case API_CALLS.UPDATE_RECURRING_PAYMENTS:
        return await RecurringPaymentApi().updateRecurringPayment(data);
      case API_CALLS.AUTH_RECURRING_PAYMENT:
        return await RecurringPaymentApi().authorizeRecurringPayment();
      case API_CALLS.GET_AVAILABLE_SEATS:
        return await SeatingApi().getAvailableSeats(data);
      case API_CALLS.RESERVE_SEATS:
        return await SeatingApi().reserveSpecificSeats(data);
      case API_CALLS.SEND_PDF:
        return await TicketApi().sendPdf();
      case API_CALLS.CANCEL_VIPPS_TRANSACTION:
        return await TicketApi().cancelPaymentTransaction(data);
      case API_CALLS.GET_VIPPS_TRANSACTION_STATUS:
        return await TicketApi().getVippsTransactionStatus(data);
      case API_CALLS.CAPTURE_NETS:
        return await TicketApi().captureTicket(data);
      case API_CALLS.TICKET_PAY:
        return await TicketApi().ticketPay();
      case API_CALLS.SEND_RECEIPT:
        return await TicketApi().sendReceipt(data);
      case API_CALLS.SEND_SMS:
        return await TicketApi().sendSMS();
      case API_CALLS.REVIEW_ORDER:
        return await TicketApi().reviewOrder();
      case API_CALLS.RESERVE_TICKET:
        return await TicketApi().reserve(data);
      case API_CALLS.ADD_CONSENT:
        return await TicketApi().addConsent();
      case API_CALLS.REFUND_TICKET:
        return await TicketApi().refundTicket(data);
      case API_CALLS.ADD_ENTITLEMENT:
        return await EntitlementApi().addEntitlement(data);
      case API_CALLS.GET_ENTITLEMENTS:
        return await EntitlementApi().getUserEntitlements();
      default:
        break;
    }
  };

  const makeCallGoOps = async (request: string, data = null) => {
    switch (request) {
      case API_CALLS.GET_STRAWBERRY_BONUSES:
        return await axios.get(`${apiConfig.baseUrlGoOps}/customer/strawberry-bonuses`, {
          headers: { Authorization: `Bearer ${user.tokenGoOps}` },
        });
      case API_CALLS.GET_STRAWBERRY_PROFILE:
        return await axios.get(`${apiConfig.baseUrlGoOps}/customer/strawberry-configuration`, {
          headers: { Authorization: `Bearer ${user.tokenGoOps}` },
        });
      case API_CALLS.UPDATE_STRAWBERRY_PROFILE:
        return await axios.post(`${apiConfig.baseUrlGoOps}/customer/edit-strawberry-configuration`, data, {
          headers: { Authorization: `Bearer ${user.tokenGoOps}` },
        });
      default:
        break;
    }
  };

  const guardedRequestGoOps = async (request: string, data = null) => {
    try {
      return await makeCallGoOps(request, data);
    } catch (error) {
      if (error.res.data.status === 401) {
        await updateToken();
      }
      return makeCallGoOps(request, data);
    }
  };

  const guardedRequest = async (request: string, data = null) => {
    try {
      return await makeCall(request, data);
    } catch (error) {
      if (error.res.data.status === 401) {
        await updateToken();
      }
      return makeCall(request, data);
    }
  };

  return {
    API_CALLS,
    requestImageUrl,
    requestCms,
    request,
    requestGoOps,
    guardedRequest,
    guardedRequestGoOps,
    ExternalCampaignsApi,
    CmsApi,
  };
};
